exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-businesses-page-js": () => import("./../../../src/templates/businesses-page.js" /* webpackChunkName: "component---src-templates-businesses-page-js" */),
  "component---src-templates-contact-us-thanks-page-js": () => import("./../../../src/templates/contact-us-thanks-page.js" /* webpackChunkName: "component---src-templates-contact-us-thanks-page-js" */),
  "component---src-templates-donate-page-js": () => import("./../../../src/templates/donate-page.js" /* webpackChunkName: "component---src-templates-donate-page-js" */),
  "component---src-templates-financial-donation-page-js": () => import("./../../../src/templates/financial-donation-page.js" /* webpackChunkName: "component---src-templates-financial-donation-page-js" */),
  "component---src-templates-fundraising-page-js": () => import("./../../../src/templates/fundraising-page.js" /* webpackChunkName: "component---src-templates-fundraising-page-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/gallery-page.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-index-orig-page-js": () => import("./../../../src/templates/index-orig-page.js" /* webpackChunkName: "component---src-templates-index-orig-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-physical-donation-page-js": () => import("./../../../src/templates/physical-donation-page.js" /* webpackChunkName: "component---src-templates-physical-donation-page-js" */),
  "component---src-templates-referral-page-js": () => import("./../../../src/templates/referral-page.js" /* webpackChunkName: "component---src-templates-referral-page-js" */),
  "component---src-templates-schools-page-js": () => import("./../../../src/templates/schools-page.js" /* webpackChunkName: "component---src-templates-schools-page-js" */),
  "component---src-templates-sensory-room-page-js": () => import("./../../../src/templates/sensory-room-page.js" /* webpackChunkName: "component---src-templates-sensory-room-page-js" */),
  "component---src-templates-success-stories-post-js": () => import("./../../../src/templates/success-stories-post.js" /* webpackChunkName: "component---src-templates-success-stories-post-js" */),
  "component---src-templates-supportus-page-js": () => import("./../../../src/templates/supportus-page.js" /* webpackChunkName: "component---src-templates-supportus-page-js" */),
  "component---src-templates-volunteering-page-js": () => import("./../../../src/templates/volunteering-page.js" /* webpackChunkName: "component---src-templates-volunteering-page-js" */)
}

